// import { useMediaQuery } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import HandAnimationPhone from 'components/handAnimation/HandAnimationPhone';
import useIsUserInteract from 'Hook/useIsUserInteract';
import React from 'react';
import { Parallax } from 'react-scroll-parallax';

/**
 * PhoneInHand component
 *
 * This component renders a section of the homepage that shows a phone in hand
 * with a sleek and innovative app, and allows the user to book personal flights
 * in seconds.
 *
 * @returns {JSX.Element}
 */
const PhoneInHand = () => {
  const isMobile = useMediaQuery('(max-width : 699px)');
  const isIntersecting = useIsUserInteract();
  if (!isIntersecting) {
    return null;
  }

  const headingChauffeur = 'Personal Air Chauffeur';
  const descriptionChauffeur = `Fly anytime with a tap — no extra fees, no fare hunting. All included in your membership`;

  const headingAppDesign = 'App Designed for the Future';
  const descriptionAppDesign = `Drag and drop to pick your seat — right from our app`;

  const clockImage = 'https://assets.blackjet.au/images/book-personal-flights-in-seconds-with-Black-Jet-app.svg';
  const sleekImg = 'https://assets.blackjet.au/images/sleek-mobile-Black-Jet-app.svg'

  return (
    // <Suspense fallback={<div>Loading HandAnimation...</div>}>

    <div className='experience-black-wrap'>
      {/* <HandAnimation className="large-ex-screen" /> */}
      <HandAnimationPhone className='large-ex-screen' />

      <div>
        <div className='experience-section'>
          <div className='transform-wrap'>
            <div className='experience-section-left'>
              <h2>Discover the Black {!isMobile && <br />} Jet App</h2>
              {/* {isMobile ? (
                <h2>
                  Experience
                  <br />
                  Black Jet Mobile
                </h2>
              ) : (
                <h2>
                  Experience <br />
                  Black Jet Mobile
                </h2>
              )} */}
            </div>
          </div>
          <Parallax className='transform-wrap desktop-phone' speed={20}>
            <div className='experience-section-right '>
              <div className='experience-right-card'>
                <img
                  className='experience-img'
                  src={clockImage}
                  alt='book-personal-flights-in-seconds-with-Black-Jet-app'
                />
                <div className='experience-text'>
                  <p>{headingChauffeur}</p>
                  <div className='booking-description'>
                    <h3>{descriptionChauffeur}</h3>
                  </div>
                </div>
              </div>
              <div className='experience-right-card'>
                <img
                  className='experience-img'
                  src={sleekImg}
                  alt='clock'
                />
                <div className='experience-text'>
                  <p>{headingAppDesign}</p>
                  <div className='booking-description'>
                    <h3>{descriptionAppDesign}</h3>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
        <div className='experience-section'>
          <Parallax translateX={['200px', '-200px']}>
            <div className='mobile-hand'>
              <div className='experience-section-right '>
                <div className='experience-right-card flex-col'>
                  <div className='flex gap-[10px] items-center'>
                    <img
                      className='experience-img'
                      src={clockImage}
                      alt='book-personal-flights-in-seconds-with-Black-Jet-app'
                    />
                    <div className='experience-text'>
                      <p>{headingChauffeur}</p>
                    </div>
                  </div>

                  <div className='experience-text'>
                    <div className='booking-description '>
                      <h3>{descriptionChauffeur}</h3>
                    </div>
                  </div>
                </div>
                <div className='experience-right-card flex-col'>
                  <div className='flex gap-[10px] items-center'>
                    <img
                      className='experience-img'
                      src={sleekImg}
                      alt='clock'
                    />
                    <div className='experience-text'>
                      <p>{headingAppDesign}</p>
                    </div>
                  </div>
                  <div className='experience-text'>
                    <div className='booking-description'>
                      <h3>{descriptionAppDesign}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
      </div>

      {/* Tabs--screens */}
    </div>

    // </Suspense>
  );
};

export default PhoneInHand;
