import { useBlackJetContext } from 'context/OnboardingContext';
import { Helmet } from 'react-helmet-async';
/**
 * Generates structured data for the homepage of Black Jet's website.
 * This includes metadata for the website, mobile applications, web application,
 * breadcrumb navigation, and video objects. The structured data is used for
 * search engine optimization (SEO) and to provide rich snippets in search
 * engine results. The function returns a Helmet component containing a
 * script tag with JSON-LD structured data.
 */
export const HomePageStructure = () => {
  const structuredData = [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'Black Jet - Unlimited Flights for a Flat Monthly Price',
      url: 'https://blackjet.au',
      description:
        "Australia’s first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal — say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
      hasPart: [
        {
          '@type': 'AboutPage',
          name: 'About Us',
          url: 'https://blackjet.au/about',
          description:
            'Visit our About Us page to learn how Black Jet, an Australian company, is transforming aviation with a personal, stress-free, and flexible approach',
        },
        {
          '@type': 'ContactPage',
          name: 'Contact Us',
          url: 'https://blackjet.au/contact',
          description:
            'Get in touch with Black Jet’s team for assistance with memberships, the Black Jet app, or general inquiries',
        },
        {
          '@type': 'WebPage',
          name: 'Legal',
          url: 'https://blackjet.au/legal',
          description: "Black Jet's Privacy Policy, Terms of Use, and more",
        },
        {
          '@type': 'WebPage',
          name: 'Careers',
          url: 'https://blackjet.au/careers',
          description:
            'Join our team in shaping the future of aviation—more personal, stress-free, and flexible than ever',
        },
      ],
      potentialAction: [
        {
          '@type': 'BuyAction',
          // target: 'https://blackjet.au/join-black-jet?type=pre-order',
          name: 'Pre-order now',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: 'https://blackjet.au/join-black-jet ?type=pre-order',
            actionPlatform: [
              'http://schema.org/WebApplication',
              'http://schema.org/MobileWebApplication',
            ],
            httpMethod: 'GET',
          },
        },
        {
          '@type': 'ViewAction',
          // target: 'https://blackjet.au/virtual-tour',
          name: 'Tour the plane',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: 'https://blackjet.au/virtual-tour',
            actionPlatform: [
              'http://schema.org/WebApplication',
              'http://schema.org/MobileWebApplication',
            ],
            httpMethod: 'GET',
          },
        },
        {
          '@type': 'RegisterAction',
          // target: 'https://blackjet.au/join-black-jet',
          name: 'Create a free account',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: 'https://blackjet.au/join-black-jet',
            actionPlatform: [
              'http://schema.org/WebApplication',
              'http://schema.org/MobileWebApplication',
            ],
            httpMethod: 'POST',
          },
        },
      ],
    },
    {
      '@context': 'https://schema.org',
      '@type': 'MobileApplication',
      name: 'Black Jet iOS App',
      operatingSystem: 'iOS',
      applicationCategory: 'TravelApplication',
      url: 'https://apps.apple.com/us/app/black-jet/id6473786745',
      downloadUrl: 'https://apps.apple.com/us/app/black-jet/id6473786745',
      description:
        "Get the Black Jet app to experience Australia's first and only personal aviation",
      offers: {
        '@type': 'Offer',
        price: '0.00',
        priceCurrency: 'AUD',
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'WebApplication',
      name: 'Black Jet PWA',
      url: 'https://blackjet.au',
      description: "Australia's first and only personal aviation",
      applicationCategory: 'TravelApplication',
      operatingSystem: 'All',
      browserRequirements: 'Requires JavaScript',
      offers: {
        '@type': 'Offer',
        price: '0.00',
        priceCurrency: 'AUD',
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.9',
        reviewCount: '193',
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'MobileApplication',
      name: 'Black Jet Android App',
      operatingSystem: 'Android',
      applicationCategory: 'TravelApplication',
      url: 'https://play.google.com/store/apps/details?id=com.blackjet.',
      downloadUrl: 'https://play.google.com/store/apps/details?id=com.blackjet',
      description:
        "Get the Black Jet app to experience Australia's first and only personal aviation",
      offers: {
        '@type': 'Offer',
        price: '0.00',
        priceCurrency: 'AUD',
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: 'https://blackjet.au',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'Pre-order now',
          item: 'https://blackjet.au/join-black-jet?type=pre-order',
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: 'Tour the plane',
          item: 'https://blackjet.au/virtual-tour',
        },
        {
          '@type': 'ListItem',
          position: 4,
          name: 'FAQs',
          item: 'https://blackjet.au/faqs',
        },
        {
          '@type': 'ListItem',
          position: 5,
          name: 'About Us',
          item: 'https://blackjet.au/aboutus',
        },
        {
          '@type': 'ListItem',
          position: 6,
          name: 'Contact Us',
          item: 'https://blackjet.au/contactus',
        },
      ],
    },
    {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: 'Black Jet Experience',
      description:
        'Fly freely and experience Australia’s first and only personal aviation service with Black Jet',
      thumbnailUrl:
        'https://assets.blackjet.au/images/videoblackjet/BlackJet-LowBitRate-Thumbnail.png',
      uploadDate: '2025-01-01',
      contentUrl: 'https://assets.blackjet.au/images/videoblackjet/BlackJet-LowBitRate.mp4',
      creator: {
        '@type': 'Organization',
        name: 'Black Jet',
        legalName: 'Black Jet Mobility Pty Ltd',
        taxID: 'ABN: 27 656 996 244',
        duns: '750499586',
        email: 'marketing@blackjet.au',
        url: 'https://blackjet.au',
        logo: {
          '@type': 'ImageObject',
          url: 'https://assets.blackjet.au/Black-Jet-logo.svg',
        },
        image:
          'https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
        description:
          "Australia's first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
        sameAs: [
          'https://www.facebook.com/joinBlackJet',
          'https://www.instagram.com/joinBlackJet',
          'https://x.com/joinBlackJet',
          'https://www.linkedin.com/company/joinBlackJet',
        ],
        certifications: [
          {
            '@type': 'CreativeWork',
            name: 'CASA Part 135 Air Operator’s Certificate',
            url: 'https://www.casa.gov.au/rules/regulatory-framework/casr/part-135-casr-australian-air-transport-operations-smaller-aeroplanes',
          },
          {
            '@type': 'CreativeWork',
            name: 'PCI DSS v4.0 SAQ D',
            url: 'https://listings.pcisecuritystandards.org/documents/PCI-DSS-v4-0-SAQ-D-Merchant.pdf',
          },
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          email: 'hello@help.blackjet.au',
          contactType: 'Customer Support',
          areaServed: 'AU',
          availableLanguage: ['English'],
        },
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: 'A Day with Black Jet',
      description:
        "Spend an unforgettable day flying with Black Jet, Australia's first and only personal aviation service",
      thumbnailUrl:
        'https://assets.blackjet.au/images/videoadaywithblackjet/aDayWithBlackJet-Thumbnail.png',
      uploadDate: '2025-02-01',
      contentUrl: 'https://assets.blackjet.au/images/videoadaywithblackjet/aDayWithBlackJet.mp4',
      creator: {
        '@type': 'Organization',
        name: 'Black Jet',
        legalName: 'Black Jet Mobility Pty Ltd',
        taxID: 'ABN: 27 656 996 244',
        duns: '750499586',
        email: 'marketing@blackjet.au',
        url: 'https://blackjet.au',
        logo: {
          '@type': 'ImageObject',
          url: 'https://assets.blackjet.au/Black-Jet-logo.svg',
        },
        image:
          'https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
        description:
          "Australia's first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
        sameAs: [
          'https://www.facebook.com/joinBlackJet',
          'https://www.instagram.com/joinBlackJet',
          'https://x.com/joinBlackJet',
          'https://www.linkedin.com/company/joinBlackJet',
        ],
        certifications: [
          {
            '@type': 'CreativeWork',
            name: 'CASA Part 135 Air Operator’s Certificate',
            url: 'https://www.casa.gov.au/rules/regulatory-framework/casr/part-135-casr-australian-air-transport-operations-smaller-aeroplanes',
          },
          {
            '@type': 'CreativeWork',
            name: 'PCI DSS v4.0 SAQ D',
            url: 'https://listings.pcisecuritystandards.org/documents/PCI-DSS-v4-0-SAQ-D-Merchant.pdf',
          },
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          email: 'hello@help.blackjet.au',
          contactType: 'Customer Support',
          areaServed: 'AU',
          availableLanguage: ['English'],
        },
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      name: 'Inside the Black Jet Airplane',
      description: 'High-resolution photo showcasing the interior of a Black Jet plane',
      contentUrl: 'https://assets.blackjet.au/images/Black-Jet-aircraft-cabin-interior.jpg',
      thumbnailUrl: 'https://assets.blackjet.au/images/Black-Jet-aircraft-cabin-interior.jpg',
      uploadDate: '2025-02-01',
      creator: {
        '@type': 'Organization',
        name: 'Black Jet',
        legalName: 'Black Jet Mobility Pty Ltd',
        taxID: 'ABN: 27 656 996 244',
        duns: '750499586',
        email: 'marketing@blackjet.au',
        url: 'https://blackjet.au',
        logo: {
          '@type': 'ImageObject',
          url: 'https://assets.blackjet.au/Black-Jet-logo.svg',
        },
        image:
          'https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
        description:
          "Australia's first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
        sameAs: [
          'https://www.facebook.com/joinBlackJet',
          'https://www.instagram.com/joinBlackJet',
          'https://x.com/joinBlackJet',
          'https://www.linkedin.com/company/joinBlackJet',
        ],
        certifications: [
          {
            '@type': 'CreativeWork',
            name: 'CASA Part 135 Air Operator’s Certificate',
            url: 'https://www.casa.gov.au/rules/regulatory-framework/casr/part-135-casr-australian-air-transport-operations-smaller-aeroplanes',
          },
          {
            '@type': 'CreativeWork',
            name: 'PCI DSS v4.0 SAQ D',
            url: 'https://listings.pcisecuritystandards.org/documents/PCI-DSS-v4-0-SAQ-D-Merchant.pdf',
          },
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          email: 'hello@help.blackjet.au',
          contactType: 'Customer Support',
          areaServed: 'AU',
          availableLanguage: ['English'],
        },
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      name: 'Black Jet Private Lounge Terminal',
      description: 'High-resolution photo of the Black Jet Private Lounge',
      contentUrl: 'https://assets.blackjet.au/images/Black-Jet-private-lounge-terminal.jpg',
      thumbnailUrl: 'https://assets.blackjet.au/images/Black-Jet-private-lounge-terminal.jpg',
      uploadDate: '2025-02-01',
      creator: {
        '@type': 'Organization',
        name: 'Black Jet',
        legalName: 'Black Jet Mobility Pty Ltd',
        taxID: 'ABN: 27 656 996 244',
        duns: '750499586',
        email: 'marketing@blackjet.au',
        url: 'https://blackjet.au',
        logo: {
          '@type': 'ImageObject',
          url: 'https://assets.blackjet.au/Black-Jet-logo.svg',
        },
        image:
          'https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
        description:
          "Australia's first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
        sameAs: [
          'https://www.facebook.com/joinBlackJet',
          'https://www.instagram.com/joinBlackJet',
          'https://x.com/joinBlackJet',
          'https://www.linkedin.com/company/joinBlackJet',
        ],
        certifications: [
          {
            '@type': 'CreativeWork',
            name: 'CASA Part 135 Air Operator’s Certificate',
            url: 'https://www.casa.gov.au/rules/regulatory-framework/casr/part-135-casr-australian-air-transport-operations-smaller-aeroplanes',
          },
          {
            '@type': 'CreativeWork',
            name: 'PCI DSS v4.0 SAQ D',
            url: 'https://listings.pcisecuritystandards.org/documents/PCI-DSS-v4-0-SAQ-D-Merchant.pdf',
          },
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          email: 'hello@help.blackjet.au',
          contactType: 'Customer Support',
          areaServed: 'AU',
          availableLanguage: ['English'],
        },
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      name: 'Black Jet Logo Wordmark',
      description: 'Black Jet logo displayed above the wordmark',
      contentUrl:
        'http://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
      thumbnailUrl:
        'http://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
      uploadDate: '2025-02-01',
      creator: {
        '@type': 'Organization',
        name: 'Black Jet',
        legalName: 'Black Jet Mobility Pty Ltd',
        taxID: 'ABN: 27 656 996 244',
        duns: '750499586',
        email: 'marketing@blackjet.au',
        url: 'https://blackjet.au',
        logo: {
          '@type': 'ImageObject',
          url: 'https://assets.blackjet.au/Black-Jet-logo.svg',
        },
        image:
          'https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
        description:
          "Australia's first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
        sameAs: [
          'https://www.facebook.com/joinBlackJet',
          'https://www.instagram.com/joinBlackJet',
          'https://x.com/joinBlackJet',
          'https://www.linkedin.com/company/joinBlackJet',
        ],
        certifications: [
          {
            '@type': 'CreativeWork',
            name: 'CASA Part 135 Air Operator’s Certificate',
            url: 'https://www.casa.gov.au/rules/regulatory-framework/casr/part-135-casr-australian-air-transport-operations-smaller-aeroplanes',
          },
          {
            '@type': 'CreativeWork',
            name: 'PCI DSS v4.0 SAQ D',
            url: 'https://listings.pcisecuritystandards.org/documents/PCI-DSS-v4-0-SAQ-D-Merchant.pdf',
          },
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          email: 'hello@help.blackjet.au',
          contactType: 'Customer Support',
          areaServed: 'AU',
          availableLanguage: ['English'],
        },
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      name: 'Black Jet Soaring Above the Clouds',
      description: 'High-resolution photo of a Black Jet soaring above the clouds',
      contentUrl: 'https://assets.blackjet.au/images/Black-Jet-in-flight.jpg',
      thumbnailUrl: 'https://assets.blackjet.au/images/Black-Jet-in-flight.jpg',
      uploadDate: '2025-02-01',
      creator: {
        '@type': 'Organization',
        name: 'Black Jet',
        legalName: 'Black Jet Mobility Pty Ltd',
        taxID: 'ABN: 27 656 996 244',
        duns: '750499586',
        email: 'marketing@blackjet.au',
        url: 'https://blackjet.au',
        logo: {
          '@type': 'ImageObject',
          url: 'https://assets.blackjet.au/Black-Jet-logo.svg',
        },
        image:
          'https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
        description:
          "Australia's first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
        sameAs: [
          'https://www.facebook.com/joinBlackJet',
          'https://www.instagram.com/joinBlackJet',
          'https://x.com/joinBlackJet',
          'https://www.linkedin.com/company/joinBlackJet',
        ],
        certifications: [
          {
            '@type': 'CreativeWork',
            name: 'CASA Part 135 Air Operator’s Certificate',
            url: 'https://www.casa.gov.au/rules/regulatory-framework/casr/part-135-casr-australian-air-transport-operations-smaller-aeroplanes',
          },
          {
            '@type': 'CreativeWork',
            name: 'PCI DSS v4.0 SAQ D',
            url: 'https://listings.pcisecuritystandards.org/documents/PCI-DSS-v4-0-SAQ-D-Merchant.pdf',
          },
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          email: 'hello@help.blackjet.au',
          contactType: 'Customer Support',
          areaServed: 'AU',
          availableLanguage: ['English'],
        },
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      name: 'Airplane in Blue Skies',
      description: 'Airplane flying through clear, blue skies',
      contentUrl: 'https://assets.blackjet.au/images/about-Black-Jet-right-blue-sky.jpg',
      thumbnailUrl: 'https://assets.blackjet.au/images/about-Black-Jet-right-blue-sky.jpg',
      uploadDate: '2025-02-01',
      creator: {
        '@type': 'Organization',
        name: 'Black Jet',
        legalName: 'Black Jet Mobility Pty Ltd',
        taxID: 'ABN: 27 656 996 244',
        duns: '750499586',
        email: 'marketing@blackjet.au',
        url: 'https://blackjet.au',
        logo: {
          '@type': 'ImageObject',
          url: 'https://assets.blackjet.au/Black-Jet-logo.svg',
        },
        image:
          'https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
        description:
          "Australia's first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
        sameAs: [
          'https://www.facebook.com/joinBlackJet',
          'https://www.instagram.com/joinBlackJet',
          'https://x.com/joinBlackJet',
          'https://www.linkedin.com/company/joinBlackJet',
        ],
        certifications: [
          {
            '@type': 'CreativeWork',
            name: 'CASA Part 135 Air Operator’s Certificate',
            url: 'https://www.casa.gov.au/rules/regulatory-framework/casr/part-135-casr-australian-air-transport-operations-smaller-aeroplanes',
          },
          {
            '@type': 'CreativeWork',
            name: 'PCI DSS v4.0 SAQ D',
            url: 'https://listings.pcisecuritystandards.org/documents/PCI-DSS-v4-0-SAQ-D-Merchant.pdf',
          },
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          email: 'hello@help.blackjet.au',
          contactType: 'Customer Support',
          areaServed: 'AU',
          availableLanguage: ['English'],
        },
      },
    },
  ];
  return (
    <Helmet>
      <script id='structured-data-list' type='application/ld+json'>
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};
/**
 * Generates structured data for the offers page of Black Jet's website.
 * The structured data includes information about the private jet subscription
 * service offered by Black Jet, such as service type, provider details, areas
 * served, aggregate rating, and membership offers.
 * This data is used for SEO purposes and is embedded as JSON-LD in the page.
 */

export const OffersStructureData = () => {
  const { onboardingForms } = useBlackJetContext();
  const membershipInfo = onboardingForms?.membershipData;

  const serviceDataStructure = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    serviceType: 'Private Jet Subscription',
    description:
      "Fly Freely. Unlimited Sydney-Melbourne flights for a flat monthly price. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
    provider: {
      '@type': 'Organization',
      name: 'Black Jet',
      legalName: 'Black Jet Mobility Pty Ltd',
      taxID: 'ABN: 27 656 996 244',
      duns: '750499586',
      email: 'marketing@blackjet.au',
      url: 'https://blackjet.au',
      logo: {
        '@type': 'ImageObject',
        url: 'https://assets.blackjet.au/Black-Jet-logo.svg',
      },
      image:
        'https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png',
      description:
        "Australia's first and only personal aviation service offering unlimited Sydney-Melbourne flights for a flat monthly fee. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience",
      sameAs: [
        'https://www.facebook.com/joinBlackJet',
        'https://www.instagram.com/joinBlackJet',
        'https://x.com/joinBlackJet',
        'https://www.linkedin.com/company/joinBlackJet',
      ],
      certifications: [
        {
          '@type': 'CreativeWork',
          name: 'CASA Part 135 Air Operator’s Certificate',
          url: 'https://www.casa.gov.au/rules/regulatory-framework/casr/part-135-casr-australian-air-transport-operations-smaller-aeroplanes',
        },
        {
          '@type': 'CreativeWork',
          name: 'PCI DSS v4.0 SAQ D',
          url: 'https://listings.pcisecuritystandards.org/documents/PCI-DSS-v4-0-SAQ-D-Merchant.pdf',
        },
      ],
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'hello@help.blackjet.au',
        contactType: 'Customer Support',
        areaServed: 'AU',
        availableLanguage: ['English'],
      },
    },
    areaServed: [
      {
        '@type': 'Place',
        name: 'Sydney',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Sydney',
          addressRegion: 'NSW',
          addressCountry: 'AU',
        },
      },
      {
        '@type': 'Place',
        name: 'Melbourne',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Melbourne',
          addressRegion: 'VIC',
          addressCountry: 'AU',
        },
      },
      {
        '@type': 'Place',
        name: 'Brisbane',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Brisbane',
          addressRegion: 'QLD',
          addressCountry: 'AU',
        },
      },
      {
        '@type': 'Place',
        name: 'Gold Coast',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Gold Coast',
          addressRegion: 'QLD',
          addressCountry: 'AU',
        },
      },
      {
        '@type': 'Place',
        name: 'Sunshine Coast',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Sunshine Coast',
          addressRegion: 'QLD',
          addressCountry: 'AU',
        },
      },
      {
        '@type': 'Place',
        name: 'Newcastle',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Newcastle',
          addressRegion: 'NSW',
          addressCountry: 'AU',
        },
      },
      {
        '@type': 'Place',
        name: 'Wollongong',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Wollongong',
          addressRegion: 'NSW',
          addressCountry: 'AU',
        },
      },
    ],
  };

  const structuredData = {
    ...serviceDataStructure,
    offers: {
      '@context': 'https://schema.org',
      '@type': 'Offer',
      priceCurrency: 'AUD',
      availability: 'http://schema.org/InStock',
      url: 'https://blackjet.au',
      itemCondition: 'http://schema.org/NewCondition',
      eligibleRegion: {
        '@type': 'Country',
        name: 'Australia',
      },
      itemOffered: {
        '@type': 'Service',
        name: 'Unlimited Plan Membership',
        description:
          "Unlimited all-you-can-fly. Fly private between Sydney and Melbourne with Black Jet's exclusive flat monthly price",
      },
      name: membershipInfo?.name || 'Unlimited Plan Membership',
      price: membershipInfo?.discountPrice || membershipInfo?.latestPrice || '',
      description: membershipInfo?.text || '',
      additionalProperty:
        membershipInfo?.highlightsArray?.map((data) => {
          return {
            '@type': 'PropertyValue',
            name: data.highlight,
          };
        }) || [],
    },
  };
  return (
    <>
      <Helmet>
        <script id='offers-structured-data' type='application/ld+json'>
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
    </>
  );
};

export const FaqStructureData = ({ selectedTab, selectedTabData }) => {
  if (!selectedTab || !selectedTabData || selectedTabData?.length === 0) return null;

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    name: selectedTab?.name,
    mainEntity: selectedTabData?.map((data) => {
      return {
        '@type': 'Question',
        name: data?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: data?.answer.replace(/<\/?[^>]+(>|$)/g, ''), // Strip HTML tags for plain text
        },
      };
    }),
  };
  // console.log(structuredData, 'faqPageStructuredData');

  return (
    <Helmet >
      <script id='faq-structured-data' type='application/ld+json'>
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};
