import React, { memo, useCallback, useEffect, useState } from 'react';
import { addEnquiry, getEnquiryList } from 'services/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomEditor from 'components/custom-editor/CustomEditor';
import Errors from 'components/errors/Errors';
import { Link } from 'react-router';
import CommonInput from 'components/formcomponents/CommonInput';
import CommonLabel from 'components/formcomponents/CommonLabel';
import CommonSelect from 'components/formcomponents/CommonSelect';
import CommonButton from 'components/formcomponents/CommonButton';
import FormResponseContactUs from './FormResponseContactUs';
import { LinearProgress } from '@mui/material';
import MobileEmailSmartField from 'components/formcomponents/MobileEmailSmartField';
// import { saveTimeAndCheck } from 'utils';
import { AttachedFile, AttachedFileBtn } from 'components/Footer/chat/ChatArea';
import { getIPAddress, getIPV6Address } from 'helpers/index';
import { convertBase64, TIME_KEY } from 'utils/index';
import { LEGAL_REDIRECTION } from '../../constants/route';
import useCountdownTimer from 'Hook/useCountdownTimer';
import HintMessage from 'components/hint';
export const REVERSE_COUNT = 60;
const EnquiryForm = ({ children, type, enquirybg }) => {
  const schema = yup.object({
    fullName: yup
      .string()
      .required('Full name required. Include both your first and last name.')
      .test(
        'has-space',
        'Full name required. Include both your first and last name.',
        (value) => typeof value === 'string' && value.trim().includes(' '),
      )
      .typeError('Full name required. Include both your first and last name.'),
    email: yup
      .string()
      .required('Please provide a valid email address (e.g., john@icloud.com)')
      .email('Please provide a valid email address (e.g., john@icloud.com)'),
    phone: yup
      .number()
      .required('Please enter contact number')
      .typeError('Please enter contact number'),
    subject: yup.string(),
    enQuiry: yup.string().required('Kindly provide your enquiry in the space provided'),
    relatedEnquiry: yup.string().required('Please select an option from the dropdown'),
    type: yup.string(),
    selectedFile: yup.mixed(),
    isConfirm: yup
      .boolean()
      .oneOf([true], 'Please agree to the above')
      .required('Please agree to the above'),
  });

  const [enquiryList, setEnquiryList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [mobile, setMobile] = useState();
  // const [errorMessage, setErrorMessage] = useState('');
  const [checkNumber, setCheckNumber] = useState(true);
  const [flag, setFlag] = useState('https://flagcdn.com/au.svg');
  const [countryCode, setCountryCode] = useState('+61');
  const [loading, setLoading] = useState(false);
  const [hintMessage, setHintMessage] = useState('');
  const [attachedFiles, setAttachedFiles] = useState({
    media: [],
  });
  const { counter, startCountdown } = useCountdownTimer(TIME_KEY.CONTACT_FORM);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    watch,
    setError,

    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isConfirm = watch('isConfirm');

  const relatedEnquiry = watch('relatedEnquiry');

  // Handle the file selection and update the state
  const handleFileSelect = (files) => {
    setAttachedFiles((prev) => {
      const totalFiles = [...(prev?.media || []), ...files];

      // Check for file size
      const oversizedFiles = files.filter((file) => file.size > 10 * 1024 * 1024); // 10 MB in bytes
      if (oversizedFiles.length > 0) {
        setError('selectedFile', {
          type: 'custom',
          message: 'Oops! One or more files are too large. Please upload files under 10 MB each',
        });
        return prev; // Do not update state if there are oversized files
      }

      // Limit total files to 4
      if (totalFiles.length > 4) {
        const onlyFour = totalFiles.slice(0, 4);
        setError('selectedFile', {
          type: 'custom',
          message: 'Oops! Only 4 files can be uploaded at a time',
        });
        return { media: onlyFour };
      }

      return { media: totalFiles };
    });
  };

  const onSubmit = handleSubmit(async (values) => {
    const _values = { ...values };
    let files;
    const hasFiles = attachedFiles?.media?.length > 0;
    if (hasFiles) files = await convertBase64(attachedFiles?.media, setLoading);
    delete _values.isConfirm;
    delete _values.fullName;
    try {
      const device = window?.navigator.userAgent;
      const browserWindow = `${window?.innerWidth} * ${window?.innerHeight} px`;
      const computerScreen = `${window?.screen.width} x ${window?.screen.height} px, ${window?.screen.colorDepth}`;
      const ipv4 = await getIPAddress();
      const ipv6 = await getIPV6Address();
      // const res = '';
      const res = await addEnquiry({
        ..._values,
        device,
        browserWindow,
        computerScreen,
        ipv4,
        ipv6,
        firstName: values.fullName,
        phone: countryCode + _values.phone,
        type: type,
        attachments: files || [],
      });
      startCountdown(REVERSE_COUNT);
      setLoading(false);
      if (res?.data?.status_code === 200) {
        setIsFormSubmitted(true);
        reset();
        // saveTimeAndCheck(10, TIME_KEY.CONTACT_FORM);
      }
    } catch (error) {
      setIsFormSubmitted(false);
      console.log(error);
    }
  });

  const handleClearFile = async (e, ele, index) => {
    setAttachedFiles((prev) => {
      return { media: prev.media.filter((item, i) => i !== index) };
    });
  };

  const handleEditChange = useCallback(
    (value) => {
      setValue('enQuiry', value);
      trigger('enQuiry');
    },
    [setValue, trigger],
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await getEnquiryList();
        const data = res?.data?.data;
        const filterData = data.filter((ele) => !ele.memberStatus);
        // console.log(res.data);
        setEnquiryList(filterData.map((item) => ({ label: item?.name, value: item?._id })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (import.meta.env.SSR) {
    return;
  }

  if (isFormSubmitted) {
    return (
      <div className={` ${enquirybg || ''} enquiry-form-wrap`}>
        {children}
        <FormResponseContactUs setIsFormSubmitted={setIsFormSubmitted} />
      </div>
    );
  }

  return (
    <div>
      {loading ? (
        <div className='loader-wrap-editor'>
          <LinearProgress color='#000' />
        </div>
      ) : (
        ''
      )}
      <div className={` ${enquirybg || ''} enquiry-form-wrap`}>
        {children}
        <form className='contact-us-form-wrap' onSubmit={onSubmit}>
          <div className='row contact-form-card'>
            <div className='col-md-12 mb-8 pl-0 pr-0'>
              <CommonLabel label='Full name' />
              <CommonInput
                placeholder='Enter your full name'
                register={register}
                name='fullName'
                error={errors?.fullName}
                controlled={false}
              />
              <Errors error={errors?.fullName} message={errors?.fullName?.message} />
            </div>

            <div className='col-md-12 mb-8 p-0'>
              <CommonLabel label='Email' />
              <CommonInput
                placeholder='Enter your email address'
                register={register}
                name='email'
                error={errors?.email}
                controlled={false}
              />
              <Errors error={errors?.email} message={errors?.email?.message} />
            </div>
            <div className='col-md-12 mb-8 p-0'>
              <CommonLabel label='Contact number' />
              <MobileEmailSmartField
                isPhone={true}
                mobile={mobile}
                setHintMessage={setHintMessage}
                setMobile={(value) =>
                  setMobile(() => {
                    setValue('phone', +value);
                    return value;
                  })
                }
                errorMessage={errors?.phone ? errors?.phone?.message : ''}
                checkNumber={checkNumber}
                setCheckNumber={setCheckNumber}
                flag={flag}
                setFlag={setFlag}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                isNumeric={true}
              />
              <HintMessage message={hintMessage} />

              <Errors error={errors?.phone} message={errors?.phone?.message} />
            </div>
            <div className='col-md-12 mb-8 p-0'>
              <CommonLabel label='What’s your enquiry related to?' />
              <CommonSelect
                value={relatedEnquiry}
                trigger={trigger}
                placeholder='Select one'
                register={register}
                setValue={setValue}
                options={enquiryList}
                name='relatedEnquiry'
                error={errors?.relatedEnquiry}
              />
              <Errors error={errors?.relatedEnquiry} message={errors?.relatedEnquiry?.message} />
            </div>
            <div className='col-md-12 mb-8 p-0'>
              <CommonLabel label='Subject' />
              <CommonInput
                placeholder='Enter subject'
                register={register}
                name='subject'
                error={errors?.subject}
                controlled={false}
              />
              <Errors error={errors?.subject} message={errors?.subject?.message} />
            </div>
          </div>
          <div className='col-md-12 p-0'>
            <CommonLabel label='Your Enquiry? ' />
            {typeof window !== 'undefined' && (
              <CustomEditor initialValue={getValues().enQuiry} onChange={handleEditChange} />
            )}
            <Errors error={errors?.enQuiry} message={errors?.enQuiry?.message} />
          </div>

          <div className='attachment-wrap'>
            <AttachedFile
              data={attachedFiles}
              handleClearFile={handleClearFile}
              className='attached-list'
            />
            <AttachedFileBtn
              label={'Attach'}
              role='button'
              onFileSelect={(files) => handleFileSelect(files)}
            ></AttachedFileBtn>
            <Errors error={errors?.selectedFile} message={errors?.selectedFile?.message} />
          </div>

          <div className=' agree-txt-wrap mt-[10px] gap-2' id='FieldsRoot'>
            <div className={`custom-checkbox ${errors?.isConfirm ? 'checkbox-error' : ''}`}>
              <input
                type='checkbox'
                checked={isConfirm}
                id='agree-check-enquiry'
                {...register('isConfirm')}
                onChange={(e) => setValue('isConfirm', e.target.checked)}
              />
              <label htmlFor='agree-check-enquiry'>I agree to the</label>
              <Link to={LEGAL_REDIRECTION.TERMS_OF_USE} className='option-text'>
                <span className='underline'>Terms of Use</span>
              </Link>
              <span className='and-txt'> and </span>
              <Link to={LEGAL_REDIRECTION.PRIVACY_POLICY} className='option-text'>
                <span className='underline'>Privacy Policy</span>
              </Link>
              <Errors error={errors?.isConfirm} message={errors?.isConfirm?.message} />
            </div>
          </div>

          <div className=' '>
            <CommonButton
              type='submit'
              text={`${counter === 0 ? 'Submit' : `Resend in ${counter}s`}`}
              error={isSubmitting || counter > 0}
              disabled={isSubmitting || counter > 0}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default memo(EnquiryForm);
