import React from 'react';
import { useNavigate } from 'react-router';

interface MobileCloseHeaderProps {
  children: React.ReactNode;
}

const MobileCloseHeader = ({ children }: MobileCloseHeaderProps) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (window?.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/'); // Redirect to landing page if no previous history
    }
  };

  return (
    <div className='mobile-close-header-container'>
      <div className='mobile-close-header w-max' onClick={handleBack}>
        <img src='https://assets.blackjet.au/images/close-icon-white.svg' alt='close-icon' />
      </div>
      {children}
    </div>
  );
};

export default MobileCloseHeader;
