import React, { useEffect, useReducer } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Toaster } from 'react-hot-toast';

import OnboardingContext, {
  OnboardingReducer,
  initialOnboardingState,
} from 'context/OnboardingContext';

import { getMembershipDetails } from 'api/onboarding';
import { MEMBERSHIP_DATA, SAVED_LOCATION } from 'constants/actions';
import Routes from './Routes';
import { apiViewAllSavedLocation } from 'services/api';
import { IMembershipResponse } from 'services/types/api';

/**
 * The root component of the application.
 *
 * @returns {ReactElement} The root component element.
 */

interface IProps {
  membershipDetails: IMembershipResponse | null;
  // This will change as per the requirement
  legalDetails?: any;
  faqDetails?: any;
  careerDetails?: any;
  singleJobDetails?: any;
}

function App({
  membershipDetails,
  legalDetails,
  faqDetails,
  careerDetails,
  singleJobDetails,
}: IProps) {
  /**
   * The state and dispatch function for the onboarding form reducer.
   */
  const [onboardingForms, dispatchOnboardingForms] = useReducer(OnboardingReducer, {
    ...initialOnboardingState,
    membershipData: membershipDetails,
    legalDetails,
    faqDetails,
    careerDetails,
    singleJobDetails,
  });

  /**
   * Fetches the membership details from the server and dispatches the membership data action to the onboarding form reducer.
   * It's for the onboarding flow.
   * @returns {Promise<void>}
   */
  const getDetails = async () => {
    const response = await getMembershipDetails({ type: 'Unlimited' });
    if (response?.data?.status_code === 200) {
      dispatchOnboardingForms({ type: MEMBERSHIP_DATA, payload: response?.data?.data || {} });
    }
  };

  const getSavedLocation = async () => {
    const response = await apiViewAllSavedLocation();
    if (response?.data?.status_code === 200) {
      dispatchOnboardingForms({ type: SAVED_LOCATION, payload: response?.data?.data || {} });
    }
  };

  useEffect(() => {
    // Get membership details on app load
    getDetails();
    getSavedLocation();
  }, []);

  useEffect(() => {
    // Disable scroll on number input
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' &&
      document?.addEventListener('wheel', function () {
        if (!document.activeElement) return;
        if ((document.activeElement as HTMLInputElement)?.type === 'number') {
          (document.activeElement as HTMLInputElement)?.blur();
        }
      });
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      typeof window !== 'undefined' && document?.removeEventListener('wheel', () => {});
    };
  }, []);

  return (
    <OnboardingContext.Provider value={{ onboardingForms, dispatchOnboardingForms }}>
      <div
      //TODO: this is for the safe area and it will use future
      // style={{
      //   paddingTop: 'env(safe-area-inset-top)',
      //   paddingBottom: 'env(safe-area-inset-bottom)',
      //   paddingLeft: 'env(safe-area-inset-left)',
      //   paddingRight: 'env(safe-area-inset-right)',
      // }}
      >
        <React.Suspense fallback={<>Loading...</>}>
          <ParallaxProvider>
            {/* <BrowserRouter> */}
            <Routes />
            {/* </BrowserRouter> */}
          </ParallaxProvider>
        </React.Suspense>
        <Toaster
          position='bottom-center'
          reverseOrder={false}
          toastOptions={{
            // Override the default error icon
            style: {},
            className: 'toast-class',
          }}
        />
      </div>
    </OnboardingContext.Provider>
  );
}

export default App;
