export const baseMediaUrl = 'https://assets.blackjet.au';
// export const baseMediaUrl = process.env.REACT_APP_MEDIA_URL || '';

// const prodBaseMediaUrl = 'https://assets.blackjet.au';

//Header
export const backBtn = baseMediaUrl + '/images/back-btn.svg';
export const blackJetLogo = baseMediaUrl + '/images/Black Jet Logo.svg';
export const blackJetWhiteLogo = baseMediaUrl + '/images/Black-Jet-logo-white-on-header.svg';


// A Day with Black Jet Section Videos
export const aDayWithBlackJetVideo =
  baseMediaUrl + '/images/videoadaywithblackjet/aDayWithBlackJet.mp4';

// FlyFreely Section Videos
export const flyFreelyVideo = baseMediaUrl + '/images/videoblackjet/BlackJet-LowBitRate.mp4';
export const fallbackFlyFreely = baseMediaUrl + '/images/fallback-Black-Jet-Fly-Freely.jpg';

// FlyFreely Section Images
export const flightChair = baseMediaUrl + '/images/Black-Jet-aircraft-cabin-Interior.jpg';
// Booking Section Images
export const virtualTour = baseMediaUrl + '/images/Black-Jet-virtual-tour-walk-through.webp';

// Tour Section Images
//! not working
export const panoMiddleRear = baseMediaUrl + '/images/Pano Middle Rear.jpg';
export const PanoMiddle = baseMediaUrl + '/images/Pano Middle.jpg';
export const PanoRear = baseMediaUrl + '/images/Pano Rear.jpg';
export const PanoLav = baseMediaUrl + '/images/Pano Lav.jpg';
export const PanoFront = baseMediaUrl + '/images/Pano Front.jpg';

//About Us Section Images
export const aboutUsLeftImg = baseMediaUrl + '/images/about-Black-Jet-left-sydney-bridge.jpg';

// new way fly private section
export const tour = baseMediaUrl + '/images/Black-Jet-on-ramp-banner.jpg'; // zet image

// Fly from Private Terminals Section
export const privateTerminals = baseMediaUrl + '/images/Black-Jet-private-lounge-terminal.jpg';

 
// About Us Section Images
export const about1 = baseMediaUrl + '/images/about-Black-Jet-1.jpg';
export const about2 = baseMediaUrl + '/images/about-Black-Jet-2.jpg';
export const about3 = baseMediaUrl + '/images/about-Black-Jet-3.jpg';
export const about4 = baseMediaUrl + '/images/about-Black-Jet-4.jpg';
export const aboutRightImg = baseMediaUrl + '/images/about-Black-Jet-right-blue-sky.jpg';
export const aboutUsBanner1 = baseMediaUrl + '/images/about-us-banner-1.svg';

// Footer Section Images
export const faq_icon = baseMediaUrl + '/images/faq_icon.svg';
export const legal_icon = baseMediaUrl + '/images/legal_icon.svg';
export const contact_us_icon = baseMediaUrl + '/images/contact_us_icon.svg';
export const about_us_icon = baseMediaUrl + '/images/about_us_icon.svg';
export const login_icon = baseMediaUrl + '/images/login_icon.svg';

export const home_inactive = baseMediaUrl + '/images/home_inactive.svg';
export const home_user = baseMediaUrl + '/images/home-user.svg';

export const booking_active = baseMediaUrl + '/images/booking_active.svg';
export const ticket_icon = baseMediaUrl + '/images/ticket-icon.svg';

export const profile_active = baseMediaUrl + '/images/profile_active.svg';
export const footer_user = baseMediaUrl + '/images/footer-user.svg';

// fullScreen.jsx A day with black jet
export const fullScreenClose = baseMediaUrl + '/images/fullScreenClose.svg';
export const fullScreenOpen = baseMediaUrl + '/images/fullScreenOpen.svg';
export const videoMute = baseMediaUrl + '/images/videoMute.svg';
export const mute_icon = baseMediaUrl + '/images/Mute.svg';
export const skip_right = baseMediaUrl + '/images/skip-right.svg';
export const skip_left = baseMediaUrl + '/images/skip-left.svg';
export const center_pause = baseMediaUrl + '/images/center_pause.svg';
export const center_play_pause = baseMediaUrl + '/images/center_play_pause.svg';

export const tour_mobile_cross_simple = baseMediaUrl + '/images/tour-mobile-cross-simple.svg';
export const tour_desktop_cross = baseMediaUrl + '/images/tour-desktop-cross.svg';
export const tour_mobile_cross = baseMediaUrl + '/images/tour-mobile-cross.svg';
export const tour_tab_cross = baseMediaUrl + '/images/tour-tab-cross.svg';
export const tour_large_cross = baseMediaUrl + '/images/tour-large-cross.svg';
export const close_icon = baseMediaUrl + '/images/Close.svg';

// videoAnimation.jsx
export const mobile_mute_faded = baseMediaUrl + '/images/mobile_mute_faded.svg';
export const mobile_mute_unfaded = baseMediaUrl + '/images/mobile_mute_unfaded.svg';

export const mobile_unmute_faded = baseMediaUrl + '/images/mobile_unmute_faded.svg';
export const mobile_unmute_unfaded = baseMediaUrl + '/images/mobile_unmute_unfaded.svg';

export const desktop_mute_faded = baseMediaUrl + '/images/desktop_mute_faded.svg';
export const desktop_mute_unfaded = baseMediaUrl + '/images/desktop_mute_unfaded.svg';
