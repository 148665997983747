import CommonButton from 'components/formcomponents/CommonButton';
import MobileCloseHeader from 'components/Header/MobileCloseHeader';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useMediaQuery } from '@mui/material';
import FramerMotion from 'components/animations/FramerMotion';
const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 699px)');
  const [isLoading, setIsLoading] = useState(true);
  const handleOnLoad = () => {
    setIsLoading(false);
  };
  return (
    <FramerMotion key={location.pathname}>
      <div className='not-found-container'>
        {isMobile && <MobileCloseHeader />}
        <div className='not-found-body'>
          <img
            src={'https://assets.blackjet.au/images/dog-not-found.svg'}
            onLoadCapture={handleOnLoad}
            alt='404-high-bit-rate'
            className={isLoading ? 'hidden' : ''}
          />
          <img
            src={'https://assets.blackjet.au/images/dog-not-found-low-bit-rate.png'}
            alt='404-low-bit-rate'
            className={!isLoading ? 'hidden' : ''}
          />
          <h4 className='page-not-found'>
            Oops… This page has flown away—with a Frenchie on board
          </h4>
        </div>
        <div className='back-to-home'>
          <CommonButton
            onClick={() => navigate('/')}
            text={`${isMobile ? 'Close & return home' : 'Return home'} `}
          />
        </div>
      </div>
    </FramerMotion>
  );
};

export default NotFound;
